:root {
  font-size: 20px;
  --letter-width: 2.5rem;
  --stats-clues-block-size: 0.55rem;
  --stats-clues-min-width: 2rem;
  --stats-table-line-height: 0.75rem;
  --nav-height: 3rem;
  --square-button-width: 1.5rem;
  --key-width: 1.3rem;
  --game-top-padding: 0.25rem;
  --game-width: 16rem;
  /* in rgb components for use in rgba() */
  --bg-correct: 83, 141, 78;
  --bg-wrong-index: 181, 159, 59;
  --bg-wrong: 58, 58, 60;
  --bg-calc: 128, 128, 128;
  --bg-deselect-opacity: 0.5;
  --selected-button-color: #707070;
  --selected-group0-color: #008000;
  --selected-group1-color: #008080;
  --selected-group2-color: #808000;
  --selected-group3-color: #800080;
  --main-background-color: #121212;
  --not-in-dictionary: #a00000;
  --text-color: white;
  --border-color: white;
}

html {
  background-color: var(--main-background-color);
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  background: var(--main-background-color);
  height: 12px;
  width: 12px;
  -webkit-border-radius: 1ex;
}

::-webkit-scrollbar-thumb {
  background: #606060;
  -webkit-border-radius: 1ex;
  -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
}

.App {
  margin: auto;
  text-align: center;
  font-family: "Helvetica Neue", Arial, sans-serif;
  color: var(--text-color);
  max-width: 40rem;
  display: flex;
  flex-direction: column;
}

body {
  height: 100%;
  width: 100%;
  flex-direction: column;
}

.content {
  display: flex;
  flex-direction: row;
  align-content: space-around;
}

nav {
  height: var(--nav-height);
  margin: 0;
  border-bottom: 0.05rem solid grey;
  display: flex;
  flex-direction: row;
}

nav h1 {
  margin: auto;
  color: var(--text-color);
  font-size: 1.2rem;
  text-align: center;
  line-height: 1.1rem;
  flex-shrink: 1;
  max-width: 46%;
  min-width: 36%;
}

.nav-buttons {
  display: flex;
  direction: row;
  align-items: center;
  width: 24%;
}

button#help {
  border: none;
  padding-top: 0.25rem;
  background-color: #121212;
}

button#reset {
  font-weight: bold;
  font-size: 1.2rem;
  padding-top: 0.15rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.word-set-button-container {
  display: grid;
  align-content: center;
  column-gap: 0;
  width: var(--square-button-width);
}

.one-by-two-buttons {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0;
}

.memory-button-container {
  display: grid;
  align-content: center;
  grid-template-columns: 1fr 1fr;
  column-gap: 0;
  max-width: 5rem;
  min-width: 4.5rem;
}

.quordle-button-container {
  display: grid;
  align-content: center;
  width: var(--square-button-width);
  height: var(--nav-height);
}

.two-by-two-buttons {
  display: grid;
  width: var(--nav-height);
  height: var(--nav-height);
  grid-template-columns: 1fr 1fr;
  gap: 0;
}

.memory-button {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: var(--square-button-width);
  height: var(--square-button-width);
  border-radius: 0.5rem;
  border-width: 0.1rem;
}

.word-set-button {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--square-button-width);
  height: var(--square-button-width);
  text-align: center;
  border-radius: 0.5rem;
  border-width: 0.1rem;
}

.hidden {
  visibility: hidden;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  min-width: 50%;
}

.game {
  align-items: center;
  padding-top: var(--game-top-padding);
  flex-grow: 1;
}

.board {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.game-row {
  display: flex;
  min-height: 0.5rem;
  flex-shrink: 1;
  margin: 0.1rem;
}

.game-row--active {
  height: var(--letter-width);
}

.letter {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.05rem solid grey;
  margin: 0.1rem;
  width: var(--letter-width);
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--text-color);
  user-select: none;
  cursor: pointer;
}

.letter--correct {
  background-color: rgb(var(--bg-correct));
}
.letter--wrongIndex {
  background-color: rgb(var(--bg-wrong-index));
}
.letter--wrong {
  background-color: rgb(var(--bg-wrong));
}

tr {
  line-height: var(--stats-table-line-height);
}
.impossible-word-bg {
  color: #709090;
  background-color: #600000;
}
.group-bg,
.alt-group-bg {
  color: #b0b0b0;
  background-color: var(--main-background-color);
}

.bg-qmem-button-selected {
  background: conic-gradient(
    rgb(var(--bg-correct)) 90deg,
    rgb(var(--bg-wrong-index)) 90deg 180deg,
    rgb(var(--bg-correct)) 180deg 270deg,
    rgb(var(--bg-wrong)) 270deg
  );
  border-color: var(--border-color);
}
.bg-qmem-button-deselected {
  background: conic-gradient(
    rgba(var(--bg-correct), var(--bg-deselect-opacity)) 90deg,
    rgba(var(--bg-wrong-index), var(--bg-deselect-opacity)) 90deg 180deg,
    rgba(var(--bg-correct), var(--bg-deselect-opacity)) 180deg 270deg,
    rgba(var(--bg-wrong), var(--bg-deselect-opacity)) 270deg
  );
  border-color: var(--selected-group0-color);
}

.button-bg {
  background-color: #404040;
  border-color: var(--selected-button-color);
}
.selected-button-bg {
  background-color: var(--selected-button-color);
  border-color: var(--border-color);
}

.group-0-bg {
  background-color: var(--selected-group0-color);
}
.alt-group-0-bg {
  background-color: #006000;
}
.button-0-bg {
  background-color: #004000;
  border-color: var(--selected-group0-color);
}
.selected-button-0-bg {
  background-color: var(--selected-group0-color);
  border-color: var(--border-color);
}

.group-1-bg {
  background-color: var(--selected-group1-color);
}
.alt-group-1-bg {
  background-color: #005050;
}
.button-1-bg {
  background-color: #004040;
  border-color: var(--selected-group1-color);
}
.selected-button-1-bg {
  background-color: var(--selected-group1-color);
  border-color: var(--border-color);
}

.group-2-bg {
  background-color: var(--selected-group2-color);
}
.alt-group-2-bg {
  background-color: #505000;
}
.button-2-bg {
  background-color: #404000;
  border-color: var(--selected-group2-color);
}
.selected-button-2-bg {
  background-color: var(--selected-group2-color);
  border-color: var(--border-color);
}

.group-3-bg {
  background-color: var(--selected-group3-color);
}
.alt-group-3-bg {
  background-color: #500050;
}
.button-3-bg {
  background-color: #400040;
  border-color: var(--selected-group3-color);
}
.selected-button-3-bg {
  background-color: var(--selected-group3-color);
  border-color: var(--border-color);
}

td th {
  line-height: var(--stats-table-line-height);
  width: auto;
}

tbody {
  line-height: var(--stats-table-line-height);
}

.stats {
  padding: 0.5rem;
  flex-grow: 1;
  overflow-y: auto;
}

.stat-table {
  font-family: "Ubunto Mono", monospace, "DejaVu Sans Mono", "Courier New",
    Courier;
  font-weight: bold;
  width: 100%;
  max-width: 16rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
  border: 0.05rem solid gray;
  color: var(--text-color);
  line-height: var(--stats-table-line-height);
  font-size: var(--stats-table-line-height);
}

.info {
  margin-left: auto;
  margin-right: auto;
  font-size: 0.8rem;
  text-align: start;
  max-width: 16rem;
}

p {
  text-indent: 1rem;
}

.clues-column {
  min-width: var(--stats-clues-min-width);
}

.clues-container {
  display: inline-flex;
  justify-content: space-around;
  min-width: 100%;
  margin-top: 0.15rem;
  margin-bottom: 0.1rem;
}

.clue-box {
  width: var(--stats-clues-block-size);
  height: var(--stats-clues-block-size);
  display: inline-flex;
  margin-left: 0.15rem;
  border: 0.025rem solid rgb(var(--text-color));
}

.clue-box--correct {
  background-color: #68a963;
}

.clue-box--wrongIndex {
  background-color: #f0c105;
}

.clue-box--wrong {
  background-color: #787c7e;
}

.keyboard {
  margin-top: 0.5rem;
  background-color: #121212;
}

.line {
  flex: 33%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0.05rem;
}

.key {
  max-width: 8%;
  min-width: var(--key-width);
  min-height: var(--key-width);
  aspect-ratio: 1;
  margin: 0.05rem;
  border-radius: 0.15rem;
  display: grid;
  place-items: center;
  font-size: 0.8rem;
  background-color: grey;
  color: var(--text-color);
  font-family: Arial, Helvetica, sans-serif;
  cursor: pointer;
}

.medium {
  min-width: 1.6rem;
  max-width: 10%;
  aspect-ratio: auto;
}
.big {
  min-width: 3rem;
  max-width: 18%;
  aspect-ratio: auto;
}
.spacebar {
  min-width: 10.5rem;
  max-width: 60%;
  aspect-ratio: auto;
}
.disabled {
  background-color: #3a393c;
}
